import { gUploadApi, api as rootApi } from 'shared/api';

const api = gUploadApi.injectEndpoints({
  endpoints: (build) => ({
    UploadUserAvatar: build.mutation<{ success: boolean }, FormData>({
      query: (formData) => ({
        url: `/user-avatar`,
        method: 'POST',
        body: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(rootApi.util.invalidateTags([{ type: 'CurrentUser' }]));
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useUploadUserAvatarMutation } = api;
